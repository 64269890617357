<template>
  <v-form ref="form" lazy-validation>
    <v-dialog
      v-model="showform"
      max-width="1000px"
      persistent
      transition="dialog-top-transition"
      @keydown.esc="close"
    >
      <v-card>
        <v-card-title>
          <span> Liste des Equipes de Comptage </span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-row v-if="invent.nbcompte == 2"> </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <listitems
                :list="equipes"
                :qDelete="Qequipedelete"
                :Get_suf="'InvEquipe'"
                title="Equipes"
                :headers="equipes_headers"
                :master="false"
                :showedit="false"
                :add="invent.statut_id == '18'"
                :del="invent.statut_id == '18'"
                :del_disable="equipe.nbagent > 0 || equipe.nbdepot > 0"
                :key="ke"
                @open="OpenEquipeForm"
                @rowselect="EquipeChange"
              >
              </listitems>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <listitems
                :list="agents"
                :qDelete="Qagentdelete"
                :Get_suf="'InvAgent'"
                title="Operateur"
                :headers="agents_headers"
                :master="false"
                :showedit="false"
                :add="invent.statut_id == '18'"
                :add_disable="equipe.id > 0"
                :del="invent.statut_id == '18'"
                :del_disable="equipe.nbdepot > 0"
                :key="ka"
                @open="OpenAgentForm"
                @rowselect="AgentChange"
                @delete="AgentDelete"
              >
              </listitems>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer> </v-spacer>
          <v-btn color="blue darken-1" text @click.stop="close"> Fermer </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
    <template>
      <confirmdialog ref="confirm" />
    </template>
    <invequipeform
      :item="equipe"
      :items="equipes"
      :readonly="invent.statut_id != '18'"
      :key="kequipe"
      :showForm="!isEquipeClosed"
      @close="closeEquipeForm"
      :inv_id="parseInt(invent.id)"
    >
    </invequipeform>
    <invagentform
      :item="agent"
      :items="agents"
      :readonly="invent.statut_id != '18'"
      :key="kagent"
      :showForm="!isAgentClosed"
      @close="closeAgentForm"
      :equipe_id="parseInt(equipe.id)"
      :agents_list="agents_list"
      :invent="invent"
      @add="AgentAdd"
    >
    </invagentform>
  </v-form>
</template>
<script>
const listitems = () => import("./ListItems.vue");
const confirmdialog = () => import("./ConfirmDialog.vue");
const invagentform = () => import("./InvAgentForm.vue");
const invequipeform = () => import("./InvEquipeForm.vue");
import jsPDF from "jspdf";

import DELETE_INVENT_AGENT from "../graphql/Inventaire/DELETE_INVENT_AGENT.gql";
import DELETE_INVENT_EQUIPE from "../graphql/Inventaire/DELETE_INVENT_EQUIPE.gql";

export default {
  components: {
    listitems,
    confirmdialog,
    invagentform,
    invequipeform,
  },
  props: {
    invent: Object,
    agents_list: Array,
    showform: Boolean,
  },
  data() {
    return {
      isAgentClosed: true,
      isEquipeClosed: true,
      equipes_headers: [
        {
          text: "Ref",
          value: "ref",
          selected: true,
        },
        {
          text: "Nom",
          value: "label",
          slot: "href",
          selected: true,
        },
        {
          text: "Agents",
          value: "nbagent",
          selected: true,
        },
        {
          text: "Depots",
          value: "nbdepot",
          selected: true,
        },
      ],
      agents_headers: [
        {
          text: "Matricule",
          value: "matricule",
          selected: true,
        },
        {
          text: "Nom",
          value: "nom",
          slot: "href",
          selected: true,
        },
        {
          text: "Fonction",
          value: "fonction",
          selected: true,
        },
      ],
      snackbar: false,
      snackbar_timeout: 2000,
      snackbar_text: "",
      snackbar_color: "primary",
      agent: {},
      equipe: {},
      ka: 100,
      kagent: 200,
      ke: 300,
      kequipe: 400,
    };
  },
  mounted() {},
  watch: {},
  computed: {
    Qagentdelete() {
      return DELETE_INVENT_AGENT;
    },
    Qequipedelete() {
      return DELETE_INVENT_EQUIPE;
    },
    equipes() {
      return this.invent.equipes ? this.invent.equipes : [];
    },
    agents() {
      return this.equipe.agents ? this.equipe.agents : [];
    },
  },
  methods: {
    OpenEquipeForm(item) {
      this.equipe = item;
      this.isEquipeClosed = false;
      this.kequipe++;
    },
    closeEquipeForm() {
      this.isEquipeClosed = true;
    },
    EquipeChange(item) {
      this.equipe = item;
      this.ka++;
    },

    OpenAgentForm(item) {
      this.agent = item;
      this.isAgentClosed = false;
      this.kagent++;
    },
    closeAgentForm() {
      this.isAgentClosed = true;
    },
    AgentChange(item) {
      this.agent = item;
    },
    AgentAdd() {
      this.equipe.nbagent = parseInt(this.equipe.nbagent) + 1;
      this.equipes.splice(this.equipe.index, 1, this.equipe);
    },
    AgentDelete() {
      this.equipe.nbagent = parseInt(this.equipe.nbagent) - 1;
      this.equipes.splice(this.equipe.index, 1, this.equipe);
      this.ka++;
    },
    close() {
      this.$emit("close", -1);
    },

    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },
    sumCol(key) {
      // sum data in give key (property)
      return this.list.reduce((a, b) => a + (b[key] || 0), 0);
    },
    print() {
      const addHeaders = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        doc.setFont("helvetica", "italic");
        doc.setFontSize(8);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          var dataimg =
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIIAAACCCAMAAAC93eDPAAAAgVBMVEX1gh8AAAAxGgZiNAyjVxWTThNSKwpyPQ5sOQ7EaBmDRRHUcRsdDwQDAgDcdRzfdhyJSRHleR3ufh64YhdAIggXDAPRbxqPTBKZURMlFAVEJAkhEQQSCgLLbBoLBgEpFgVXLguvXRY4HgdULAupWhV4QA9LKAlDJAk8IAheMgygVRQAwNKAAAAD2klEQVR4nO2a23aqOhRAV5AIAcIlyE0gikWr/v8HnhXauluP1bbAht2R+cCoIYM1mxsJCYBGo9FoNBrNMPiuW4vpwgfOjhMkhZpGXQqtP+awY3UV7liSbSVPeZy6AYZGDQUJP2bxqLqmpB3HoOEb/+3v+wqiCcZRKIkdRxcFF6L6TSE427vCM+qLAlP5opqxztlVtVWnb+k9SLOtx3dO8KZwLlEhA4i3nGz2Ma0K8aoQkAaYwY+HHd/iz+UGL94am/IQFeTna3mI3yu4B8JPTN1rSPxO4WmjXN3kPLSCen7I4z8KO0mW6esdbl4UWliUKplVT0MpNKdLSxStpJ1CKFcrQiSNXnD3MgJvi/0xoBjKLUlxLAnZQLSpsBfLwhcmYT9XEAdygW8FuKaAHfmIgc9f420cOiRTbc80TbaVBy+kz9XBzIzCoD83gG5UdFnHa3k0hLL3vCTXJs3y+DIyCW+VjdRBMVayfpxJbKU7lgBk0njcx9tCNqMZwGn/hdfAYjteGXyRoV9WLLQX/8e2buDcIKRIjz6JHeJIBsDuo3A9CPx9BTaIQS+FcHoFa3oF+9coWFpBK2iFX6vwNMHa+krB+fsG1wplRr9L3viPw3xD4Wccek3nh2mOq+kV+qznhlLIplfYT68wgymLVvgFCjOYxJ+nV4iSyRWAeZMrgDDttfFtvCEVfiieTK4AxWAK+fJnPA9XETMYmrSCVtAKWmEkhRl8XwgfRxpZgaePI42s0OuzRHhnWsKvAlW3sx1PcR+Du1xPKxejRdIKoDZ/P6O6Unj+PGv9ONKnzGB0nMGCbgYKe62AOFphHgoz2K2dgUI2vQKdXiGfXoHdOi/Scet0yaeYPRQ0/w6C3dmCc3sdJ/oq7N6ey5L031z1s+7EnmgdqpZEqemb9GVdEIc0jVtwHbJkEDd+o3KFuW+q25HpUL9T8HPaZy2FBtWu8ATUJS8lt9SAXVVSzZUDg1SSywTahEgbjlKuofZIIiVfqWPLvOQ8VApVwvm5j0JMFjUTwqtqECeSo8IJooJEcMDnCoskrxVxJFgIhYwhWpIV+HITQLR9DlAhh5QbvYrBknwBLlmCinVCBQx1IilUJaaIPwpcnXw9dLlW0F5WkUu0hTLpY9CEwiYxJEmgprIUFbCmF6iw4o16ib1XgESVla1Kge8wsvWkSgHTN70UTLI0pA+xlMdCFcVFwfXIpiT8g0IsuYE1j22B8uqYqA8bAyhAa1uqQQehvVctnTnYzhsH/0eRW1ZzzrDFOnjj3JV8lNl7FqrG5zqLvRoSWpWe9/nCodFoNBqNRqOZD/8BpLpPM0WrUdMAAAAASUVORK5CYII=";
          doc.addImage(dataimg, "png", 15, 15, 20, 20);
          doc.setFontSize(12);
          doc.text(this.title, 15, 45);
          doc.setFontSize(9);
          doc.text("Date : " + this.$store.state.today, 170, 45);
        }
      };
      const addFooters = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        doc.setFont("helvetica", "italic");
        doc.setFontSize(8);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.text(
            "Page " + String(i) + " of " + String(pageCount),
            doc.internal.pageSize.width / 2,
            287,
            {
              align: "center",
            }
          );
        }
      };

      let head = [];
      let columns = [];
      let items = [];
      let foot = [];
      let item;
      //data formating

      for (let index = 0; index < this.list.length; index++) {
        item = {};
        this.listheaders.forEach((elm) => {
          if (elm.value == "no") {
            item[elm.value] = index + 1;
          }
          if (elm.slot == "cur22") {
            item[elm.value] = this.numberWithSpace(
              parseFloat(this.list[index][elm.value]).toFixed(2)
            );
          } else if (elm.slot == "cur" && this.list[index][elm.value]) {
            item[elm.value] = this.numberWithSpace(
              parseFloat(this.list[index][elm.value]).toFixed(2)
            );
          } else if (this.list[index][elm.value])
            item[elm.value] = this.list[index][elm.value];
        });
        items.push(item);
      }

      let columnStyles = {};
      var doc = new jsPDF("p");
      item = {};
      this.listheaders.forEach((element) => {
        head.push({
          content: element.text,
          styles: {
            halign:
              element.align == "end"
                ? "right"
                : element.align == "center"
                ? "center"
                : "left",
          },
        });

        if (element.totlib) {
          item[element.value] = "Totals";
        } else if (element.total != null) {
          if (this.sumCol(element.total))
            item[element.value] = this.numberWithSpace(
              this.sumCol(element.total).toFixed(2)
            );
          else
            item[element.value] = this.numberWithSpace(
              element.total.toFixed(2)
            );
        }

        columns.push({ dataKey: element.value });
        columnStyles[element.value] = {
          halign:
            element.align == "end"
              ? "right"
              : element.align == "center"
              ? "center"
              : "left",
        };
      });

      if (this.Total) foot.push(item);

      doc.autoTable({
        margin: { top: 50 },
        headStyles: {
          valign: "middle",
          fillColor: [128, 128, 128],
        },
        columnStyles: columnStyles,
        head: [head],
        body: items,
        columns: columns,
        foot: foot,
        footStyles: {
          halign: "right",
          fillColor: [128, 128, 128],
        },
        showHead: "everyPage",
        showFoot: "everyPage",
      });
      addHeaders(doc);
      addFooters(doc);
      doc.setProperties({ title: this.title });
      doc.output("pdfobjectnewwindow");
    },
  },
};
</script>
